<template>
		<main class="evaluation">
			<header>
				<p v-for="(item,index) in breakFastLists" :key="index">{{item.name}}<span id="" v-if="index<breakFastLists.length-1">></span></p>
			</header>
			<section>
				<header>服务评价</header>
				<section v-for="(dataList,idx) in dataLists" :key="idx">
					<div id="" class="ly-1">
						<h1>{{dataList.title}}</h1>
						<div id="" class="ly-1-star">
							<span class="star" v-for="(star,starIndex) in dataList.grade" :key="starIndex">X</span>
							<span class="grade">{{dataList.grade}}</span>
						</div>
					</div>
					<div id="" class="ly-2-comment">
						<h2>{{dataList.comment}}</h2>
					</div>
					<div id="" class="ly-3-subTitle">
						<span v-for="(sub,subIndex) in dataList.subs">{{sub}}</span>
					</div>
					<div id="" class="ly-4-person">
						<img src="../../../home/images/askicon.jpg" >
						<p class="email">{{dataList.email}}</p>
						<p class="time">{{dataList.time}}</p>
						<div id="" class="response" :class="{gray:!dataList.isResponse}" @click="addComment(idx)">
							{{dataList.isResponse?'回复':'已回复'}}
						</div>
					</div>
					<div id="" class="ly-5-answer" v-if="dataList.isHaveAnswer">
						<img src="../../../home/images/askicon.jpg" >
						<div id="" class="answer-name">
							<div id="">
								<span>{{dataList.answers.name}}</span>
								<span>{{dataList.answers.time}}</span>
							</div>
							<p>{{dataList.answers.comment}}</p>
						</div>
					</div>
					<div id="" class="ly-5-print" v-if="dataList.isShowCommentDiv">
						<textarea rows="" cols="" placeholder="请输入回复内容"></textarea>
						<div id="" class="buttons">
							<div class="" @click="dataList.isShowCommentDiv=false">取消</div>
							<div class="">回复</div>
						</div>
					</div>
				</section>
			</section>
		</main>
</template>

<script>

	export default {
		components:{},
		data(){
			return{
				breakFastLists:[{name:'我的项目'},{name:'服务评价'}],
				dataLists:[
					{
						grade:5,
						title:'网络招工平台解决方案',
						comment:'非常专业和转世，非常善于沟通，以后还会合作',
						subs:['网页设计','平面设计','标志设计'],
						email:'546781507@qq.com',
						time:'2021-03-19 13:25:34',
						isResponse:true,
						isHaveAnswer:false,
						isShowCommentDiv:false,
						answers:{
							imgSrc:'',
							name:'飞翔的企鹅',
							time:'20121212121',
							comment:'thank U'
						}
					},
					{
						grade:5,
						title:'网络招工平台解决方案',
						comment:'非常专业和转世，非常善于沟通，以后还会合作',
						subs:['网页设计','平面设计','标志设计'],
						email:'546781507@qq.com',
						time:'2021-03-19 13:25:34',
						isResponse:false,
						isHaveAnswer:true,
						isShowCommentDiv:false,
						answers:{
							imgSrc:'',
							name:'飞翔的企鹅',
							time:'20121212121',
							comment:'thank U'
						}
					},
					{
						grade:5,
						title:'网络招工平台解决方案',
						comment:'非常专业和转世，非常善于沟通，以后还会合作',
						subs:['网页设计','平面设计','标志设计'],
						email:'546781507@qq.com',
						time:'2021-03-19 13:25:34',
						isResponse:true,
						isHaveAnswer:true,
						isShowCommentDiv:false,
						answers:{
							imgSrc:'',
							name:'飞翔的企鹅',
							time:'20121212121',
							comment:'thank U'
						}
					}
				],
				// isShowCommentDiv:false
			}

		},
		methods:{
			addComment(index){
				if(this.dataLists[index].isResponse){
					this.$set(this.dataLists[index],'isShowCommentDiv',true)
				}

			}
		}
	}
</script>

<style lang="less" scoped>
	.gray{
		background-color: #e7e7e7 !important;
	}
	.evaluation{

		&>header{
			display: flex;
			background-color: #Fff;
			height: 50px;
			align-items: center;
			padding: 0 12px;
		}
		&>section{
			margin-top: 20px;
			background-color: #fff;
			padding: 0 24px;
			&>header{
				font-size: 20px;
				border-bottom: 1px solid #e7e7e7;
				height: 80px;
				line-height: 80px;
			}
		&>section{

			border-bottom: 1px solid #e7e7e7;
			padding-bottom: 24px;

			.ly-1{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 0;
			}
			.ly-2-comment{
				font-size: 14px;
				color: #505050;
			}
			.ly-3-subTitle{
				margin-top: 15px;
				color: #00A2E6;
				span{
					margin: 0 12px;
					position: relative;
					&:not(:last-of-type)::after{
						content: '';
						position: absolute;
						width: 2px;
						background-color: #00A2E6;
						right: -12px;
						top: 4px;
						bottom: 2px;
					}
				}
			}
			.ly-4-person{
				display: flex;
				position: relative;
				margin-top: 15px;
				align-items: center;
				.email{
					margin-left: 12px;
				}
				.time{
					margin-left: 22px;
					font-size: 12px;
					color: #575757;
				}
				.response{
					position: absolute;
					right: 0;
					font-size: 12px;
					width: 120px;
					height: 24px;
					line-height: 24px;
					text-align: center;
					background-color: #00A2E6;
					color: #fff;
				}
			}
			.ly-5-answer{
				background-color: #F8F8F8;
				display: flex;
				padding: 12px;
				margin-top: 12px;
				align-items: center;
				&>img{
					flex-shrink: 0;
					width: 40px;
					height: 40px;
				}
				.answer-name{
					display: felx;
					margin-left: 20px;
					flex-direction: column;
					flex-grow: 1;
					&>div{
						border-bottom: 1px solid black;
						padding: 10px 0;
						width: 100%;
						span:last-of-type{
							margin-top: 12px;
							font-size: 12px;
							color: #575757;
							margin-left: 40px;
						}
					}
					&>p{
						padding: 10px 0;
						color: #575757;
						font-size: 12px;

					}
				}
			}
			.ly-5-print{
				width: 100%;
				margin-top: 12px;
				text-align: center;
				&>textarea{
					width: 100%;
					height: 60px;
				}
				&>.buttons{
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 12px;
					&>div{
						font-size: 12px;
						margin: 12px;
						background-color: #00A2E6;
						height: 24px;
						width: 80px;
						color: #fff;
						line-height: 24px;
						&:first-of-type{
							background-color: #B5B5B5;
						}
					}
				}
			}
		}
		}
	}
</style>
